import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, setSubmitFailed, stopSubmit } from 'redux-form';
import SelectField from 'components/forms/controls/V2/SelectField';
import InputField from 'components/forms/controls/V2/InputField';
import Button from 'components/forms/controls/ButtonV2';
import CheckBoxField from 'components/forms/controls/V2/CheckBoxField';
import { get, uniq, concat, isEmpty } from 'lodash';
import { required, validPostalOrZipCode } from 'services/validate';
import gaTrack, { GA_TR_SCHEDULING_SHIPPING_ADDRESS_PAGE } from '../../../../../services/gaTrack';
import { HOME, SHIPPING } from '../../../../../services/utils';
import classnames from 'classnames';
import {
  hydrateShippingAddressFields,
} from '../../../../../services/api/transformers/addressTransformers';
import './ShippingAddressPage.scss';

class ShippingAddressPage extends Component {
  state = {
    initialState: {},
    shippingSameAsHomeAddress: false
  };

  componentDidMount() {
    gaTrack(GA_TR_SCHEDULING_SHIPPING_ADDRESS_PAGE);
    window.scrollTo(0, 0);
    const { initialValues, allAddress,dispatch,errors } = this.props;
    let newAddress = [...allAddress];

    const homeSameAsShipping = newAddress.find(
      el =>
        el.addressType &&
        el.addressType.includes(HOME) &&
        el.addressType.includes(SHIPPING)
    );

    if (homeSameAsShipping) {
      const homeAddress = newAddress.find(
        el => el.addressType && el.addressType.includes(HOME)
      );
      this.setState(prevState => ({
        initialState: {
            ...prevState.initialState,
            shippingAddressLine1: homeAddress?.addressLine1,
            shippingAddressLine2: homeAddress?.addressLine2,
            shippingAddressState: homeAddress?.addressState,
            shippingAddressCity: homeAddress?.addressCity,
            shippingAddressZipCode: homeAddress?.addressZipCode,
            shippingAddressCountry: homeAddress?.addressCountry,
            shippingSameAsHomeAddress: true
        },
        shippingSameAsHomeAddress: initialValues?.shippingSameAsHomeAddress
        })
      );
    } else {
      if (initialValues) {
        this.setState({
          initialState: initialValues,
          shippingSameAsHomeAddress: initialValues?.shippingSameAsHomeAddress
        });
      }
    }

    if(errors && errors.delivery){
      dispatch(stopSubmit('shipping-address-form',errors.delivery));
      dispatch(setSubmitFailed('shipping-address-form',Object.keys(errors.delivery)));
    }
  }

  handleChange = (value, key) => {
    this.setState(
      { initialState: Object.assign({}, this.state.initialState, { [key]: value }) }
    );
  };

  onSubmit = async values => {
    const { allAddress, user } = this.props;
    const { shippingSameAsHomeAddress } = this.state;
    let newAllAddress = [...allAddress];

    const oldObj = newAllAddress.find(
      el => el && el.addressType && el.addressType.includes(SHIPPING)
    );

    if (!isEmpty(oldObj)) {
      const oldIndex = newAllAddress.findIndex(
        el => el && el.addressType && el.addressType.includes(SHIPPING)
      );
      let oldType = [...get(oldObj, 'addressType', [])];

      if (oldType.length > 1) {
        let shippingIndex = oldType.indexOf(SHIPPING);
        if (shippingIndex > -1) {
          oldType.splice(shippingIndex, 1);
        }
        newAllAddress[oldIndex] = { ...oldObj, addressType: oldType };
      } else {
        newAllAddress = newAllAddress.filter(
          el => el && el.addressType && !el.addressType.includes(SHIPPING)
        );
      }
    }

    if (shippingSameAsHomeAddress) {
      const obj = newAllAddress.find(el => el && el.addressType && el.addressType.includes(HOME));
      const index = newAllAddress.findIndex(
        el => el && el.addressType && el.addressType.includes(HOME)
      );
      const newType = uniq(concat(get(obj, 'addressType', []), [SHIPPING]));
      newAllAddress[index] = { ...obj, addressType: newType };
    }  else {
      const {
        shippingAddressLine1,
        shippingAddressLine2,
        shippingAddressCity,
        shippingAddressState,
        shippingAddressZipCode,
        shippingAddressCountry
      } = this.state.initialState;
      const data = { shippingAddressLine1, shippingAddressLine2, shippingAddressCity, shippingAddressState, shippingAddressZipCode,shippingAddressCountry };
      newAllAddress.push(
        hydrateShippingAddressFields({
          ...data,
          shippingAddressCountry: shippingAddressCountry,
          fullName: user?.fullName,
          addressType: [SHIPPING],
          addressId: get(oldObj, 'addressId', undefined),
        })
      );
    }
    await this.props.onSubmit(newAllAddress);
  };

  setBillingAsShippingAddress = (e, value) => {
    const { initialValues, allAddress = [] } = this.props;
    let newAddress = [...allAddress];
    const hasHomeAddress = newAddress.some(el => el.addressType &&  el.addressType.includes(HOME));

    this.setState({ shippingSameAsHomeAddress: value }, () => {
      if (value) {
        if (hasHomeAddress) {
          const insuranceBillingAddress = newAddress.find(
            el => el.addressType && el.addressType.includes(HOME)
          );

          this.setState(prevState => ({
            initialState: {
                ...prevState.initialState,
                shippingAddressLine1: insuranceBillingAddress?.addressLine1,
                shippingAddressLine2: insuranceBillingAddress?.addressLine2,
                shippingAddressState: insuranceBillingAddress?.addressState,
                shippingAddressCity: insuranceBillingAddress?.addressCity,
                shippingAddressZipCode: insuranceBillingAddress?.addressZipCode,
                shippingAddressCountry: insuranceBillingAddress?.addressCountry,
                shippingSameAsHomeAddress: value
            },
            }),
            () => this.props.initialize({
                ...this.state.initialState,
            })
          );
        }
      } else {
        this.props.initialize({
          ...this.state.initialState,
          shippingAddressLine1: (initialValues && initialValues.shippingAddressLine1) || '',
          shippingAddressLine2: (initialValues && initialValues.shippingAddressLine2) || '',
          shippingAddressCity: (initialValues && initialValues.shippingAddressCity) || '',
          shippingAddressState: (initialValues && initialValues.shippingAddressState) || '',
          shippingAddressZipCode: (initialValues && initialValues.shippingAddressZipCode) || '',
          shippingSameAsHomeAddress: value
        });
      }
    });
  };

  isDisableShippingFields = () => {
    if (this.state.shippingSameAsHomeAddress) {
      return true;
    }
    return false;
  };

  getCountryStates = (country="US") => {
    const { CountriesStateEnums: { states } } = this.props;
    const selectedCountryStates = (states || []).filter(a => a.countryCode == country);
    return selectedCountryStates.map(state => {
      return {
        label: state.code,
        value: state.code,
        key: state.code,
      };
    });
  };

  isShowSameAsBillingAddressCheckbox = () => {
    const { allAddress } = this.props;
    const homeAddress = allAddress.find(
      el => el.addressType && el.addressType.includes(HOME)
    );
    return !isEmpty(homeAddress);
  };

  render() {
    const { submitting, toPreviousStep, valid, handleSubmit } = this.props;

    return (
      <div className="scp-schedule-wizard scp-shipping-address-stage">
        <h2 className="gm-shipping-address_header">Shipping Address</h2>
        <form className="scp-home-address-form" onSubmit={handleSubmit(this.onSubmit)}>
          <section className="main-fields-container">
            <section className="fields-container">
              {this.isShowSameAsBillingAddressCheckbox() && <div className="form-row">
                <div className="form-col">
                  <Field
                    name="shippingSameAsHomeAddress"
                    component={CheckBoxField}
                    type="checkbox"
                    extraclassName={classnames(this.state.shippingSameAsHomeAddress ? 'shipping-as-home-address-selected' : '')}
                    onChange={this.setBillingAsShippingAddress}
                    label={
                      <>
                        <span className="checkmark" />
                        <div className="label-section">Same as Billing Address</div>
                      </>
                    }
                  />
                </div>
              </div>}
              <div className="form-row">
                <div className="form-col">
                  <div className="label-section required">Address Line 1</div>
                  <Field
                    name="shippingAddressLine1"
                    placeholder="123 Main Street"
                    type="text"
                    component={InputField}
                    validate={[required('Address Line 1')]}
                    onChange={(e, value) => this.handleChange(value, 'shippingAddressLine1')}
                    disabled={this.isDisableShippingFields()}
                  />
                  <div className="upload-content info-text">
                    We cannot ship to PO Boxes. Enter a physical address.
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-col">
                  <div className="label-section">Address Line 2</div>
                  <Field
                    name="shippingAddressLine2"
                    placeholder="Apt 123"
                    type="text"
                    component={InputField}
                    onChange={(e, value) => this.handleChange(value, 'shippingAddressLine2')}
                    disabled={this.isDisableShippingFields()}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-col city-col">
                  <div className="label-section required">City</div>
                  <Field
                    name="shippingAddressCity"
                    type="text"
                    placeholder="Enter"
                    component={InputField}
                    validate={[required('City')]}
                    onChange={(e, value) => this.handleChange(value, 'shippingAddressCity')}
                    disabled={this.isDisableShippingFields()}
                  />
                </div>

                <div className="form-col state-col">
                  <div className="label-section required">State</div>
                  <Field
                    name="shippingAddressState"
                    component={SelectField}
                    placeholder="Select"
                    options={this.getCountryStates(this.state.initialState?.shippingAddressCountry)}
                    validate={[required('State')]}
                    onChange={(e, value) => this.handleChange(value, 'shippingAddressState')}
                    disabled={this.isDisableShippingFields()}
                    typeahead={true}
                    clearable={true}
                  />
                </div>
                <div className="form-col zip-col">
                  <div className="label-section required">Zip Code</div>
                  <Field
                    name="shippingAddressZipCode"
                    type="text"
                    placeholder="Enter"
                    component={InputField}
                    label="Zipcode"
                    validate={[
                      required('Zip Code'),
                      validPostalOrZipCode(
                        'Zip Code',
                        this.state.initialState?.addressCountry ||
                          this.props.initialValues?.addressCountry
                      ),
                    ]}
                    onChange={(e, value) => this.handleChange(value, 'shippingAddressZipCode')}
                    disabled={this.isDisableShippingFields()}
                  />
                </div>
              </div>
            </section>
            <section className="shipping-address-info-container">
              {this.props.description||" We need to collect a shipping address in order to send a genetic test kit to you, at the recommendation of your counselor."}
            </section>
          </section>
          <section className="action-buttons">
            {toPreviousStep && (
              <Button className="button button-back" onClick={toPreviousStep}>
                Back
              </Button>
            )}
            <Button className="button button-next" type="submit" disabled={submitting || !valid}>
              Next
            </Button>
          </section>
        </form>
      </div>
    );
  }
}

ShippingAddressPage.propTypes = {
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  toPreviousStep: PropTypes.function,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  allAddress: PropTypes.array,
  CountriesStateEnums: PropTypes.object
};

export default reduxForm({
  form: 'shipping-address-form',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(ShippingAddressPage);
