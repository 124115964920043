import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import TestInfoBox from './TestInfoBox';

class TestInfoBoxContainer extends React.Component {
  render() {
    const { type, encounter, className, geneticTestLab } = this.props;
    return <TestInfoBox type={type} encounter={encounter} geneticTestLab={geneticTestLab} className={className} />;
  }
}

TestInfoBoxContainer.propTypes = {
  className: PropTypes.string,
  encounters: PropTypes.array,
  type: PropTypes.string,
};

const mapDispatchToProps = () => ({});

const mapStateToProps = (state, ownProps) => {
  const { type, encounters, className } = ownProps;
  const sortedEncounters = _.orderBy(encounters, el => new moment(el.createdAt), ['desc']);
  const encounter = _.get(sortedEncounters, '0', {});
  const geneticTestLab =_.get(state,['geneticTest',['data']])
  return { type, encounter, className,geneticTestLab };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestInfoBoxContainer);
