import React from 'react';
import ReactDOM from 'react-dom';
import App, { appId } from './App';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.GM_ENV,
  release: process.env.REACT_APP_PATIENT_PORTAL_TAG,
});

ReactDOM.render(<App />, document.getElementById(appId));
