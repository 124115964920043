import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputField from 'components/forms/controls/V2/InputField';

import CustomCombobox from 'components/forms/controls/CustomComboboxwithHelp';
import DropzoneField from 'components/scp/forms/documents/Dropzone/DropzoneField';
import Button from 'components/forms/controls/ButtonV2';
import { descriptionText, required, trimmedText } from 'services/validate';
import { documentTypesDescription } from 'constants/DocumentsConstants';
import _ from 'lodash';

import { Field, reduxForm } from 'redux-form';
import ModalDialog from 'components/modals/ModalDialog';
import schedulingService from 'services/api/schedulingService';
import CustomComboboxwithIcon from 'components/forms/controls/CustomComboboxwithIcon';
import 'components/forms/controls/IconModalPopup.scss';
class ModalUploadingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  onCancel = () => {
    this.setState({ show: false });
  };
  onIconClick = () => {
    this.setState({ show: true });
  };
  render() {
    const isMobile = schedulingService.isMobile();

    const defaultTypeItem = { label: 'Select document type', value: undefined, disabled: true };
    const semidocTypes = this.props.documentTypes
      ? [defaultTypeItem].concat(
          this.props.documentTypes.map(d => ({ label: d.name, value: d.id }))
        )
      : [];
    const docTypes = semidocTypes.map(el => {
      el.description = _.get(documentTypesDescription, el.label.toLowerCase(), '');
      return el;
    });
    const { handleSubmit, allFieldsEnable, uploadingStatus, valid, uploadingDocument } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        {!isMobile && (
          <DropzoneField
            autoupload={false}
            multiple={false}
            className="uploading-modal-dropzone"
            fileTypeText="PDF, JPEG, JPG, GIF, and PNG with a size less than 50 MB"
            {...this.props}>
            <Field
              label="Document Type"
              name="documentTypeId"
              component={CustomCombobox}
              className="document-type-field required"
              placeholder="Select"
              items={docTypes}
              validate={[required('Document type')]}
            />
          </DropzoneField>
        )}
        {isMobile && (
          <DropzoneField
            autoupload={false}
            multiple={false}
            className="uploading-modal-dropzone"
            fileTypeText="PDF, JPEG, JPG, GIF, and PNG with a size less than 50 MB"
            {...this.props}>
            <Field
              label="Document Type"
              name="documentTypeId"
              component={CustomComboboxwithIcon}
              className="document-type-field required"
              placeholder="Select"
              items={docTypes}
              onIconClick={this.onIconClick}
              validate={[required('Document type')]}
            />
          </DropzoneField>
        )}
        <div className="col-section">
          <div className="label-section required">Document Name</div>
          <div className="input-section">
            <Field
              name="fileName"
              component={InputField}
              disabled={!allFieldsEnable}
              validate={[required('Document Name')]}
              placeholder="i.e. Lab result, Insurance claim, etc"
              trim={true}
              type="text"
            />
          </div>
        </div>
        <div className="col-section description-field">
          <div className="label-section required">Description</div>
          <div className="input-section">
            <Field
              name="description"
              component={InputField}
              disabled={!allFieldsEnable}
              placeholder="i.e. Lab result, Insurance claim, etc"
              validate={[descriptionText, trimmedText, required('Description')]}
              trim={true}
              type="textarea"
            />
          </div>
        </div>
        <div className="btn-container">
          <Button
            type="submit"
            disabled={uploadingStatus.uploading || !allFieldsEnable || !valid || uploadingDocument}>
            Upload
          </Button>
        </div>
        <ModalDialog
          modalHeader={'Document Types'}
          className="scpcustom-upload-document-dialog"
          show={this.state.show}
          backdrop="static"
          onHide={this.onCancel}
          roleModal={'scp-modal-custom'}>
          <div className="scp-uploading-modal" data-hj-suppress>
            {docTypes.map(
              o =>
                o.description && (
                  <div className="col-section">
                    <div
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}>
                      <h2>{o.label} </h2>
                      <p>{o.description}</p>
                    </div>
                    <hr style={{ borderTop: '3px solid #bbb;' }} />
                  </div>
                )
            )}
          </div>
        </ModalDialog>
      </form>
    );
  }
}

ModalUploadingForm.propTypes = {
  dropzoneOnDrop: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  uploadingStatus: PropTypes.object.isRequired,
  getFileLink: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  documentTypes: PropTypes.array,
  allFieldsEnable: PropTypes.bool,
};

export default reduxForm({
  form: 'qn-reason-for-consult-form',
  enableReinitialize: true,
})(ModalUploadingForm);
