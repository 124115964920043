export const NateraOrderQuestionnare = [
    {
        "name": "BILLING_TYPE",
        "type": "enum",
        "options": [
            "Bill Insurance",
            "Self pay (test processing may require advance payment)"
        ],
        "depends_on": {},
        "is_required": "yes",
        "display_text": "What type of billing?",
        "question_text": "What type of billing?",
        "default_option": "Bill Insurance",
    },
    {
        "name": "BLOOD_BY",
        "type": "enum",
        "options": [
            "Natera to follow up with patient for sample collection (mobile phleb, or saliva) ",
            "Clinic to Manage Specimen collection"
        ],
        "depends_on": {},
        "is_required": "no",
        "display_text": "Method/Type of collection",
        "question_text": "Method/Type of collection",
        "default_option": "Natera to follow up with patient for sample collection (mobile phleb, or saliva)",
    },
    {
        "name": "CS_ETHNICITY",
        "type": "enum",
        "options": [
            "African American",
            "Ashkenazi Jewish",
            "Caucasian",
            "East Asian",
            "French Canadian / Cajun",
            "Hispanic",
            "Mediterranean",
            "South-East Asian",
            "Sephardic Jewish",
            "Other"
        ],
        "depends_on": {},
        "is_required": "no",
        "display_text": "Ethnicity of Patient",
        "question_text": "Ethnicity of Patient",
        "default_option": "",
    },
    {
        "name": "CS_HORIZON_TSE",
        "type": "enum",
        "options": [
            "No",
            "Yes"
        ],
        "depends_on": {},
        "is_required": "no",
        "display_text": "Tay-Sachs Enzyme Add-on",
        "question_text": "Tay-Sachs Enzyme Add-on",
        "default_option": "",
        "patient_portal_config": {}
    },
    {
        "name": "CS_PART_CONSENT",
        "type": "enum",
        "options": [
            "Yes",
            "No"
        ],
        "depends_on": {},
        "is_required": "yes",
        "display_text": "Practice ensures that HIPAA consent is obtained and will make available to Natera upon request",
        "question_text": "Practice ensures that HIPAA consent is obtained and will make available to Natera upon request",
        "default_option": "Yes",
    },
    {
        "name": "SPECIMEN_TYPE",
        "type": "enum",
        "options": [
            "Blood",
            "Saliva"
        ],
        "depends_on": {},
        "is_required": "yes",
        "display_text": "Specimen Type?",
        "question_text": "Specimen Type?",
        "default_option": "Saliva",
    },
    {
        "name": "PREGNANT",
        "type": "enum",
        "options": [
            "No",
            "Yes"
        ],
        "depends_on": {},
        "is_required": "yes",
        "display_text": "Is this patient pregnant?",
        "question_text": "Is this patient pregnant?",
        "default_option": "",
        "patient_portal_config": {}
    },
    {
        "name": "HCS_ICD",
        "type": "enum",
        "options": [
            "Z34.81 (Supervision of other normal pregnancy, 1st trimester)",
            "Z34.82 (Supervision of other normal pregnancy, 2nd trimester)",
            "Z31.430 (Female: genetic disease carrier status for procreative management)",
            "Z31.440 (Male: genetic disease carrier status for procreative management)",
            "Other ICD-10 Code"
        ],
        "depends_on": {},
        "is_required": "yes",
        "display_text": "ICD-10 Code (For additional options refer natera.com/icd10codes)",
        "question_text": "ICD-10 Code (For additional options refer natera.com/icd10codes)",
        "default_option": "",
        "patient_portal_config": {}
    },
    {
        "name": "OTHER_ICD_CODE",
        "type": "text",
        "options": [],
        "depends_on": {
            "name": "HCS_ICD",
            "value": "Other ICD-10 Code"
        },
        "is_required": "conditional",
        "display_text": "Other ICD-10 Code",
        "question_text": "Other ICD-10 Code",
        "default_option": "",
    }
]

export const  formatOrderQues = (obj)=>{
    let icd10codes = "";
    if(obj.sexAssignedAtBirth == 'male')
        icd10codes = "Z31.440 (Male: genetic disease carrier status for procreative management)";
    else if(obj.sexAssignedAtBirth == "female" && obj.aoeForm.PREGNANT == "No")
        icd10codes= "Z31.430 (Female: genetic disease carrier status for procreative management)";
    else if(obj.sexAssignedAtBirth == "female" && obj.aoeForm.PREGNANT == "Yes" && obj.aoeForm.TRIMESTER == "First")
        icd10codes="Z34.81 (Supervision of other normal pregnancy, 1st trimester)"
    else if(obj.sexAssignedAtBirth == "female" && obj.aoeForm.PREGNANT == "Yes" && obj.aoeForm.TRIMESTER == "Second")
        icd10codes="Z34.82 (Supervision of other normal pregnancy, 2nd trimester)"
    else if(obj.sexAssignedAtBirth == "female" && obj.aoeForm.PREGNANT == "Yes" && obj.aoeForm.TRIMESTER == "Third")
        icd10codes="Z31.430 (Female: genetic disease carrier status for procreative management)";
    let _OrderQuestionnaire={
        "ICD-10 Code (For additional options refer natera.com/icd10codes)":[icd10codes],
        "I authorize Natera to share patient's Horizon test results with partner and his/her medical provider":[obj.aoeForm.partnerAuth == true?"Yes":"No"],
        'Method/Type of collection':['Natera to follow up with patient for sample collection (mobile phleb, or saliva)'],
        'Practice ensures that HIPAA consent is obtained and will make available to Natera upon request':['Yes'],
        'Specimen Type?':['Saliva'],
        'What type of billing?':['Bill Insurance']
    };
    if(obj.sexAssignedAtBirth == 'male')
        _OrderQuestionnaire["Is this patient pregnant?"]= ["No"];
    else
        _OrderQuestionnaire["Is this patient pregnant?"]= [obj.aoeForm.PREGNANT];

    if(obj.aoeForm.partnerDOB){
        const partnerDOB = moment(obj.aoeForm.partnerDOB, 'MM/DD/YYYY', true).isValid()?moment(obj.aoeForm.partnerDOB).format('YYYY-MM-DD'):obj.aoeForm.partnerDOB
        _OrderQuestionnaire["Partner's DOB (MM/DD/YYYY)"]=[partnerDOB];
    }
        
    if(obj.aoeForm.partnerEmail)
        _OrderQuestionnaire["Partner's Email (username@domain.com)"]=[obj.aoeForm.partnerEmail]

    if(obj.aoeForm.partnerFirstName && obj.aoeForm.partnerLastName)
        _OrderQuestionnaire["Partner's name (LAST NAME, FIRST NAME)"]=[`${obj.aoeForm.partnerLastName}, ${obj.aoeForm.partnerFirstName}`]
    else if(obj.aoeForm.partnerFirstName||obj.aoeForm.partnerLastName){
        let fullName = '';
        if(obj.aoeForm.partnerFirstName)
            fullName = obj.aoeForm.partnerFirstName;
        else if(obj.aoeForm.partnerLastName)
            fullName = obj.aoeForm.partnerLastName;
        _OrderQuestionnaire["Partner's name (LAST NAME, FIRST NAME)"]=[fullName];
    }
        

    return _OrderQuestionnaire;
  }

export const orderQuestionnare = NateraOrderQuestionnare;