import React, { Component } from 'react';
import 'services/polyfill';
import { Provider } from 'react-redux';
import { Router, Route, IndexRoute, Redirect, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { i18n } from 'gm-element-react';
import locale from 'gm-element-react/src/locale/lang/en';
import { isNull } from 'lodash';
import api from 'services/api/api';
import authService from 'services/api/authService';
import { me } from 'ducks/user';
import { fetchInitialMeta, checkLetestMeta } from './ducks/appmeta';
import { activateEmail, logout } from 'ducks/auth/email';
import { removeAction, REDIRECT_AFTER_LOGIN } from 'ducks/misc/redirectActions';
import { setSessionSourceType } from 'ducks/scheduling';
import configureStore from './store/configureStore';
import Containers from './containers';
import commonScheduling from 'services/wizardWorkflows';
import GeneralLayout, {
  PUBLIC_LAYOUT_TYPE_V2,
  WEBINAR_LAYOUT_TYPE,
  SCP_PATIENT_LAYOUT_TYPE,
  SCP_NAVIGATOR_LAYOUT_TYPE,
  SCP_EDUCATION_LAYOUT_TYPE,
} from 'containers/layouts/GeneralLayout';
import GuardComponent from 'containers/GuardComponent';
import EducationGuard from 'containers/EducationGuard';
import AfterLoginGuard from 'containers/AfterLoginGuard';
import 'react-select/dist/react-select.css';
import './styles/styles.scss';
import gaTrack, { GA_TR_REDIRECT_TO_PORTAL } from 'services/gaTrack';
import { Scope } from './constants/CommonConstants';
import { shouldBeMasked } from './services/utils';

const store = configureStore();
i18n.use(locale);

// Create an enhanced history that syncs navigation events with the store
const history = syncHistoryWithStore(browserHistory, store);

export function scrollToTop() {
  document.getElementById(appId).scrollTop = 0;
}

function activateAccountAndRedirect(next) {
  const { activationToken } = next.params;
  const { dispatch } = store;
  if (activationToken) {
    // Activate account immediately if activation token provided,
    // or display login dialog on error
    dispatch(activateEmail(activationToken))
      .then(() => {
        if (api.hasToken()) browserHistory.push('/patient/home');
      })
      .catch(() => {
        browserHistory.push('/');
      });
  } else browserHistory.push('/');
}

function redirectUnauthorizedUser() {
  if (!api.hasToken()) browserHistory.push('/');

  App.clearRedirectActions();
  App.ensureUserIsLoaded();
}

function redirectAuthorizedUser() {
  const isSetPAsswordPath = window.location.pathname.includes('/password/set');

  if (api.hasToken()) {
    const userScopes = api.scopesofToken();
    const scopeIncludeAstrik = userScopes.find((a) => a == '*');
    if (!scopeIncludeAstrik && isSetPAsswordPath) {
      api.setToken(null);
    } else {
      browserHistory.push('/patient/home');
    }
  }
}

function allowOnlySCPUsers() {
  if (api.hasToken() && api.isNonSCPUser()) browserHistory.push('/patient/home');
}

function allowOnlyNonSCPUsers() {
  if (api.hasToken() && api.isSCPUser()) browserHistory.push('/patient/home');
}

class App extends Component {
  static ensureUserIsLoaded() {
    const currentUser = authService.getCurrentUser(store.getState());
    const { dispatch } = store;
    if (api.hasToken() && currentUser === null) {
      const userScopes = api.scopesofToken();
      if (userScopes) {
        const scopeIncludeAstrik = userScopes.find((a) => a == '*');
        if (!scopeIncludeAstrik) {
          return;
        }
      }
      dispatch(me());
    }
  }

  static clearRedirectActions() {
    const { dispatch } = store;
    const {
      redirectActions: { actions },
    } = store.getState();
    if (actions[REDIRECT_AFTER_LOGIN]) {
      dispatch(removeAction(REDIRECT_AFTER_LOGIN));
    }
  }

  static getPreselectedService() {
    const currentUser = authService.getCurrentUser(store.getState());
    if (!currentUser) return undefined;
    const preselectedService = authService.getPreselectedService();
    return preselectedService;
  }

  static pathForPreselectedService(preselectedService, step) {
    return `/scheduling/${preselectedService}/${step}`;
  }

  constructor() {
    super();

    const { dispatch } = store;
    const url_medium = window.location.search.match(/url_medium=+\w+/)?.[0] ?? null;
    if (!isNull(url_medium)) dispatch(setSessionSourceType(url_medium.replace('url_medium=', '')));
  }

  componentDidMount() {
    const referrer = document.referrer;
    if (referrer) gaTrack(GA_TR_REDIRECT_TO_PORTAL);
    const timeoutmiliseonds = process.env.GM_ENV === 'production' ? 60 * 60000 : 5 * 60000;
    const { dispatch } = store;
    dispatch(fetchInitialMeta());
    this.interval = setInterval(() => {
      dispatch(checkLetestMeta());
    }, timeoutmiliseonds);

    App.ensureUserIsLoaded();
  }

  onRouteUpdate() {
    const { dispatch } = store;
    // scroll to top on any route change
    scrollToTop();
    if (api.scopesofToken()) {
      const userScopes = api.scopesofToken();
      const scopeIncludeAstrik = userScopes.find((a) => a == '*');
      if (!scopeIncludeAstrik) {
        const pathName = window.location.pathname;
        const selectedPathNameScope = Scope[pathName];
        const hasUserScope = userScopes.find((a) => a == selectedPathNameScope);
        if (!hasUserScope) {
          dispatch(logout());
        }
      }
    }
  }

  onEnterScheduling(next, replace) {
    if (!api.hasToken() && next.location.pathname.startsWith('/scheduling')) {
      return replace(next.location.pathname.replace(/\/scheduling/, '/register'));
    }
    const { params } = next;
    if (
      !api.hasToken() &&
      params.step !== 'account' &&
      params.step !== 'eligibility' &&
      params.step !== 'selection'
    ) {
      return replace('/register/account');
    }
    // Unknown wizard step or service
    if (!commonScheduling.find((s) => s.path === params.step)) {
      return replace('/scheduling/select-service');
    }
  }

  forceLogout() {
    api.setToken(null);
  }

  getRouter() {
    return (
      <Router history={history} onUpdate={this.onRouteUpdate}>
        <Route path="/registration" component={Containers.RegistrationEntryPoint}>
          <Route path=":partner" />
        </Route>
        {!shouldBeMasked(['production']) ? (
        <Route path="/test/registration" component={Containers.TestRequestRegistrationEntryPoint}>
          <Route path=":partner" />
        </Route>
          ):<div />}

        <Route path="/" component={GeneralLayout(PUBLIC_LAYOUT_TYPE_V2)}>
          <IndexRoute component={Containers.LoginPage} hideSignIn />
          <Route path="login" component={Containers.LoginPage} hideSignIn />
          <Route path="password/forgot" component={Containers.ForgotPasswordPage} />
          <Route
            path="password/reset/:resetPasswordToken"
            component={Containers.SetPasswordPage}
            reset
          />
          <Route
            path="password/set/:resetPasswordToken"
            component={Containers.SetPasswordPage}
            onEnter={redirectAuthorizedUser}
          />
          <Route
            path={'register'}
            key={'register'}
            component={Containers.RegistrationWizardPage}
            onEnter={this.onEnterScheduling}
          >
            <Route path=":service/:step" />
            <Route path=":step" />
          </Route>

          {!shouldBeMasked(['production']) ? (
            <Route path="test/register" component={Containers.TestRequestWizardPage}>
            </Route>
          ):<div />}
        </Route>

        <Route
          path={'scheduling'}
          key={'scheduling'}
          component={GuardComponent}
          onEnter={this.onEnterScheduling}
        >
          <Route component={GeneralLayout(SCP_PATIENT_LAYOUT_TYPE)}>
            <Route component={AfterLoginGuard}>
              <Route component={Containers.SCPSchedulingWizardPage}>
                <Route path=":service/:step" />
                <Route path=":step" />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="privacy" component={Containers.PrivacyPage} />
        <Route path="consent" component={Containers.ConsentToTreatmentPage} />
        <Route path="selection/thankyou" component={Containers.ThankuYouSelectionPage} />

        <Route path="/welcome" onEnter={redirectUnauthorizedUser}>
          <Route component={GuardComponent}>
            <Route component={GeneralLayout(PUBLIC_LAYOUT_TYPE_V2)}>
              <IndexRoute component={Containers.WelcomePage} />
            </Route>
          </Route>
        </Route>

        <Route path="/patient" onEnter={redirectUnauthorizedUser}>
          <Route component={GuardComponent}>
            <Route component={GeneralLayout(SCP_PATIENT_LAYOUT_TYPE)}>
              <Route component={AfterLoginGuard}>
                <IndexRoute component={Containers.SCPMyGuidePage} />
                <Route path="reschedule" component={Containers.SCPReschedulePage} />
                <Route path="home" component={Containers.SCPHome} />
                <Route path="profile" component={Containers.SCPProfile} />
                <Route path="results" component={Containers.SCPMyHealthPage} />
                <Route path="grailgalleri" component={Containers.GrailGalleriPage} />
                <Route path="switchaffiliation" component={Containers.GrailWelcomePage} />
                <Route path="redirect-for-switch" component={Containers.SwitchAffiliation} />
                <Route path="consent" component={Containers.ClinicalTrialContainer} />

                <Route
                  path="questionnaire"
                  component={Containers.QuestionnairePage}
                  onEnter={allowOnlyNonSCPUsers}
                />
                <Route
                  path="education"
                  component={Containers.EducationPage}
                  onEnter={allowOnlySCPUsers}
                />
                <Route
                  path="testrequest"
                  onEnter={allowOnlySCPUsers}
                  component={Containers.SCPShippingWizardPage}
                >
                  <Route path=":step" />
                </Route>
                <Route
                  path="testorder"
                  onEnter={allowOnlySCPUsers}
                  component={Containers.SCPTestOrderRequestConfirmContainer}
                />
                <Route
                  component={Containers.OutreachAppointment}
                  path="outreach(/:appointmentId)"
                />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="/clinical-consent">
          <IndexRoute component={Containers.ClinicalConsentContainerBeforeLogin} />
        </Route>

        {/*Paths from outside*/}
        <Route path="/" component={GeneralLayout(PUBLIC_LAYOUT_TYPE_V2)}>
          <Route
            path="appointments/:appointmentId/waiting-room"
            component={Containers.SCPWaitingRoom}
          />
          <Route path="book/appointment(/:service)" component={Containers.RedirectToSchedule} />
          <Route
            path="appointments/:appointmentId/switch-to-phone"
            name="switch-to-phone"
            component={Containers.RedirectFromOutside}
          />
          <Route
            path="appointments/:appointmentId/switch-to-video"
            name="switch-to-video"
            component={Containers.RedirectFromOutside}
          />
          <Route
            path="/patient/survey"
            name="questionnaire"
            component={Containers.RedirectFromOutside}
          />
          <Route
            path="/patient/webinar"
            name="webinar"
            component={Containers.RedirectFromOutside}
          />
          <Route
            path="/patient/consultation"
            name="patient"
            component={Containers.RedirectFromOutside}
          />
          <Route
            path="account/activate/:activationToken"
            onEnter={activateAccountAndRedirect}
            component={Containers.RedirectFromOutside}
          />
          <Route
            path="appointments/outreach(/:appointmentId)"
            name="appointment-outreach"
            component={Containers.RedirectFromOutside}
          />
          {/* We are not supporting this route for new emails. we will keep supporting this for exisiting emails. */}
          <Route
            path="documents/:documentid/view"
            name="document-view"
            component={Containers.RedirectFromOutside}
          />
          <Route
            path="documents/view"
            name="document-view"
            component={Containers.RedirectFromOutside}
          />
          <Route
            path="/patient/consent-outreach"
            name="consent-outreach"
            component={Containers.RedirectFromOutside}
          />
        </Route>

        <Redirect from="appointment/completed" to="/patient" />

        <Route path="/scp">
          <Route component={GeneralLayout(PUBLIC_LAYOUT_TYPE_V2)}>
            <Route path="registration" component={Containers.SCPRegistrationEntryPoint}>
              <Route path=":partner" />
            </Route>

            <Route path="softregistration" component={Containers.SoftRegistration}>
              <Route path=":step" />
            </Route>

            <Route path="softlogin" component={Containers.SoftLoginContainer} />

            <Route path="fullregistration" component={Containers.FullRegistration} />
          </Route>

          <Route component={GeneralLayout(SCP_NAVIGATOR_LAYOUT_TYPE)}>
            <Route path="navigator" component={Containers.SCPNavigatorPage} />
          </Route>

          <Route component={GeneralLayout(SCP_EDUCATION_LAYOUT_TYPE)}>
            <Route component={EducationGuard}>
              <Route path="education" component={Containers.SCPEducationModulePage} />
            </Route>
          </Route>
        </Route>

        <Route path="referral" component={Containers.ReferralIframe} />

        {/* routes related to webinar */}
        <Route
          path="webinar/:partner/signup(/:step)" // this path is used for setting custom router path
          component={Containers.WebinarRegistrationWizardPage}
        />

        <Route
          path="webinar"
          component={GeneralLayout(WEBINAR_LAYOUT_TYPE)}
          onEnter={redirectUnauthorizedUser}
        >
          <IndexRoute component={Containers.WebinarGuidePage} />
        </Route>

        <Route
          path="access-your-genome-program"
          component={Containers.SimpleWebinarPage('invitae')}
        />

        <Redirect from="*" to="/" />
      </Router>
    );
  }

  render() {
    return <Provider store={store}>{this.getRouter()}</Provider>;
  }
}

export default App;
export const appId = 'app';
export function getStore() {
  return store;
}
