import _ from 'lodash';
import React, { Component, Fragment } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from 'containers/pages/patient/SCP/ProfilePage/CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

class CreditCardDetail extends Component {
    state = {
        validateCreditCard: false,
    };
    render() {
        const {
            clientSecret,
            loading,
            billingDetails: {
                name,
                country,
                postal_code
            },
            isMobile,
            handleComponentState
        } = this.props;
        const options = {
            clientSecret: clientSecret,
            appearance: {
                variables: {
                    fontFamily: "'RionaSans-Medium', Arial, Helvetica, sans-serif",
                    spacingUnit: '6px'
                },
                rules: {
                    '.Label': {
                        fontSize: '14px',
                        textTransform: 'capitalize',
                        lineHeight: "16.8px",
                        color: "#525968"
                    },
                    '.Input': {
                        padding: isMobile ? '11px 8px' : '8px 8px',
                        fontSize: '13px',
                        border: "1px solid #bac3ce",
                        height:'40px'
                    },
                    '.Input:focus': {
                        borderColor: '#70cbf1'
                    },
                    '.Input--invalid': {
                        backgroundColor: 'rgba(254, 145, 137, 0.5)',
                        boxShadow: 'none'
                    },
                    '.Error': {
                        fontSize: '12px',
                        color: '#ff0000'

                    }
                }
            }
        };

        return (
            <div className="card-section">
                <div className="section-header">Credit or Debit Card</div>
                {!loading &&
                    <div className="form-row">
                        <Elements stripe={stripePromise} options={options}>
                            <CheckoutForm
                                handleComponentState={handleComponentState}
                                defaultValues={
                                    {
                                        billingDetails: {
                                            name: name,
                                            address: {
                                                country,
                                                postal_code
                                            },
                                        }
                                    }
                                } />
                        </Elements>
                    </div>
                }
            </div>
        );
    }
}

export default CreditCardDetail;
