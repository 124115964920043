import PropTypes from 'prop-types';
import React, { Component, Fragment, useEffect, useState } from 'react';
import { Field, reduxForm, formValueSelector, stopSubmit, setSubmitFailed } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import _ from 'lodash';
import {
  required,
  email,
  patientName,
  phoneOrEmpty,
  dateIsPast,
} from 'services/validate';
import Alert from 'components/widgets/Alert/Alert';
import './SignupForm.scss';
import Button from 'components/forms/controls/ButtonV2';
import 'element-theme-default';
import InputField from 'components/forms/controls/V2/InputField';
import CheckBoxField from 'components/forms/controls/V2/CheckBoxField';
import RadioButtonGroup from 'components/forms/controls/RadioButtonGroup';
import PhoneNumberField from 'components/forms/controls/V2/PhoneNumberField';
import InputMaskField from 'components/forms/controls/V2/InputMaskField';
import AOEForm from '../AOEForm/AOEForm';
import { usePrevious } from '../../../../hooks/usePrevious';
import PartnerForm from './PartnerForm';

const sexAssignedAtBirthVariants = [
  { label: 'Female', value: 'female' },
  { label: 'Male', value: 'male' },
];

const SignupForm = (props) => {

  const {
    handleSubmit,
    submitting,
    formError,
    valid,
    isMobile,
    onSubmit,
    initialValues,
    initialize,
    errors,
    dispatch,
    change
  } = props;

  const [initialState, setInitialState] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    setInitialState({ ...initialValues });
    if (errors && errors.patient) {
      dispatch(stopSubmit('sign-up-form', errors.patient));
      dispatch(setSubmitFailed('sign-up-form', Object.keys(errors.patient)));
    }
  }, []);

  const consentToTextMessage = () => {
    return 'By checking this box, I agree to receive automated text notifications from Genome Medical. These notifications may be about appointments, test results, and more.';
  }

  const handleChange = (label, value) => {
    const {aoeForm} = initialState
    if (label == 'sexAssignedAtBirth') {
      setInitialState(prevState => ({
        ...prevState,
        aoeForm:{
          ...aoeForm,
          PREGNANT:null,
          TRIMESTER:null
        },
        [label]: value
      }));
      change('aoeForm.PREGNANT', null);
      change('aoeForm.TRIMESTER', null);
    }
    else {
      setInitialState(prevState => ({
        ...prevState,
        [label]: value
      }));
    }

  }
  const handleAOEChange = (label, value) => {
    const {aoeForm} = initialState

    if(label == 'PREGNANT'){
      setInitialState(prevState => ({
        ...prevState,
        aoeForm: { 
          ...prevState.aoeForm,
          TRIMESTER:null,
           [label]: value
           }
      }));
      change('aoeForm.TRIMESTER', null);
    }
    else {
      setInitialState(prevState => ({
        ...prevState,
        aoeForm: { ...prevState.aoeForm, [label]: value }
      }));
    }
    if(['partnerFirstName','partnerLastName','partnerDOB','partnerEmail'].includes(label)){

      handlePartnerAuth({
        ...aoeForm,
        [label]:value
      });
    }
  }

  const handlePartnerAuth = (aoeForm) =>{
    if(aoeForm && !_.isEmpty(aoeForm)){
      const {partnerFirstName,partnerLastName,partnerDOB,partnerEmail} = aoeForm;
      if(!partnerFirstName && !partnerLastName && !partnerDOB && !partnerEmail){
        change('aoeForm.partnerAuth',false);
        setInitialState(prevState => ({
          ...prevState,
          aoeForm: { ...prevState.aoeForm, 'partnerAuth': false }
        }))
      }
    }
  }

  const onFormSubmit = (values) => {
    onSubmit({
      ...values
    })
  }

  return (
    <section className="signup-wrapper test-request">
      <section className="signup-header">
        <div className="genome-header-logo">
          <img className="genome-header-img" src={require('images/logo.svg')} />
        </div>
        <div className="genome-header-title">
          <h2 className="signup-title">Get Started</h2>
          <h3 className="signup-desc">
            Tell us about yourself so you can access the services that are right for you.
          </h3>
        </div>
      </section>
      <section className="signup-body">
        <form
          className="signup-form"
          autoComplete="off"
          onSubmit={handleSubmit(onFormSubmit)}
          noValidate
        >
          <div className="form-section">
            <div className="info-section">
              <h3 className="header">Patient Details</h3>
            </div>
            <div className="body-section">
              <Fragment>
                <div className="row-section">
                  <div className="col-section">
                    <div className="label-section required"> First Name</div>
                    <div className="input-section">
                      <Field
                        name="firstName"
                        type="text"
                        placeholder='First Name'
                        trim={true}
                        component={InputField}
                        validate={[required('First Name'), patientName]}
                        onChange={(e, value) => handleChange('firstName', value)}
                      />
                    </div>
                  </div>
                  <div className="col-section">
                    <div className="label-section required">Last Name</div>
                    <div className="input-section">
                      <Field
                        name="lastName"
                        type="text"
                        placeholder='Last Name'
                        trim={true}
                        component={InputField}
                        validate={[required('Last Name'), patientName]}
                        onChange={(e, value) => handleChange('lastName', value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row-section">
                  <div className="col-section">
                    <div className="label-section required">Email Address</div>
                    <div className="input-section">
                      <Field
                        name="email"
                        type="email"
                        placeholder="Email Address"
                        trim={true}
                        component={InputField}
                        validate={[required('Email'), email]}
                        onChange={(e, value) => handleChange('email', value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row-section phone-number-section">
                  <div className="col-section">
                    <div className="label-section required">Phone Number</div>
                    <div className="input-section">
                      <Field
                        name="primaryPhone"
                        type="text"
                        placeholder="Phone Number"
                        component={PhoneNumberField}
                        mask="+1 999 999-9999"
                        maskChar={null}
                        validate={[phoneOrEmpty, required('Phone Number')]}
                        onChange={(e, value) => handleChange('primaryPhone', value)}
                      />
                      {isMobile && (
                        <div className="child-input-section">
                          <Field
                            name={'primaryPhoneConsentToText'}
                            type="checkbox"
                            label={
                              <Fragment>
                                <span className="checkmark" />
                                <div className="label-section">
                                  <span> {consentToTextMessage()}</span>
                                </div>
                              </Fragment>
                            }
                            component={CheckBoxField}
                            onChange={(e, value) => handleChange('primaryPhoneConsentToText', value)}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-section">
                    <div className="label-section required"> Date of Birth</div>
                    <div className="input-section">
                      <Field
                        name="dob"
                        placeholder="MM/DD/YYYY"
                        component={InputMaskField}
                        mask="99/99/9999"
                        maskChar={null}
                        validate={[required('Date of Birth'), dateIsPast()]}
                        onChange={(e, value) => handleChange('dob', value)}
                      />
                    </div>
                  </div>
                  {!isMobile && (
                    <div className="col-section full-col consent-text">
                      <div className="label-section">Consent to Text</div>
                      <div className="input-section consent-to-text">
                        <Field
                          name={'primaryPhoneConsentToText'}
                          type="checkbox"
                          label={
                            <Fragment>
                              <span className="checkmark" />
                              <div className="label-section">{consentToTextMessage()}</div>
                            </Fragment>
                          }
                          component={CheckBoxField}
                          onChange={(e, value) => handleChange('primaryPhoneConsentToText', value)}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-section">
                    <div className="label-section required">Sex Assigned at Birth</div>
                    <div className="input-section">
                      <Field
                        name="sexAssignedAtBirth"
                        type="select"
                        placeholder="Select"
                        component={RadioButtonGroup}
                        children={sexAssignedAtBirthVariants}
                        validate={[required('Sex Assigned at Birth')]}
                        onChange={(e, value) => handleChange('sexAssignedAtBirth', value)}
                      />
                    </div>
                  </div>
                </div>
                {<AOEForm
                  initialState={initialState}
                  handleChange={handleAOEChange}
                />}
              </Fragment>

            </div>
          </div>
          <PartnerForm 
          initialState={initialState}
          handleChange={handleAOEChange}
          />

          <div className="signup-footer">
            <div className="sign-button-wrapper">
              <div className="btn-section">
                <Button className="forgot-button" type="submit"
                  disabled={submitting || !valid}>
                  <span>Continue</span>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </section>
      {/* </section> */}
    </section>
  )
}

SignupForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
  showMedicareQuestion: PropTypes.string,
};

const reduxSignupForm = reduxForm({
  form: 'sign-up-form',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true
})(SignupForm);

export default reduxSignupForm;
