import React from 'react';
const NateraBodyContent = () => {
  return (
    <article className="natera-story grey-text">
      <h2>PATIENT ACKNOWLEDGMENT </h2>
      <p className="">I have been informed of and understand the risks, benefits and alternatives to the genetic tests ordered herein for me as outlined below and hereby consent to genetic testing.
      </p>
      <p>
        <ul>
          <li>I understand that the test results may inform me of a genetic condition that is present in myself and that may cause risk for my offspring or family members. If anything is identified, there may be required medical follow-up.</li>
          <li>I understand that negative results do not rule out the possibility of a genetic condition in my fetus and/or myself. </li>
          <li>I understand the alternative to genetic testing is to not have genetic testing performed at all, and to follow other routine care. This genetic testing is optional, but available to help inform risk.</li>
          <li>I understand that in rare scenarios my carrier status may not be able to be determined and additional analysis may be recommended.</li>
          <li>I certify that I am 18 years of age or older.</li>
        </ul>
      </p>
      <p>I authorize Natera or other provider to share the information on this form and my test results with my health insurer/health plan (“plan”) on my behalf, with all benefits of my plan made payable directly to Natera or other provider. I understand that I am responsible for (a) costs not paid by my plan directly to Natera for tests ordered, including, without limitation, any copayments, deductibles, or amounts deemed ‘patient responsibility’ and (b) any amounts paid to me by my plan. This testing will not be covered by my plan if it is outside of the plan’s coverage guidelines or deemed not medically necessary –(e.g. where prior authorization is required but not obtained) and I will be responsible for the cost of such testing between $249 and $1590 per test, discounts may apply. </p>
      <p>I assign to Natera the right to appeal on my behalf negative coverage decisions made by my plan and to assert all rights and claims reserved to me as the beneficiary thereof. I authorize Natera to charge my credit card for any balance I might owe with regard to my tests. The information obtained from my tests may be used in scientific publications or presentations, but my specific identity will not be revealed. Natera may contact my healthcare provider to obtain more information regarding clinical correlation and confirmatory testing. My leftover samples may be de-identified and used for research and development (New York residents excluded). I and my heirs will not receive payments, benefits, or rights to any resulting products or discoveries. If I do not want my samples used, I may send a request in writing to Natera Sample Retention Department at the address below within 60 days after test results have been issued and my samples will be destroyed.
      </p>
      <p>
        <div>Natera, Inc.</div>
        <div>201 Industrial Road, Suite 410</div>
        <div>San Carlos, CA 94070</div>

      </p>
      <p>
        By my signature I acknowledge I have read and agreed to the Patient Acknowledgment for testing. By providing the information included herein, I understand and agree I may be contacted via, e.g., e-mail, or cellular or home phone, by text message, automatic telephone dialing system, or computer assisted technology for treatment options, billing/collection matters, and health-related products, services, or studies. I understand that my treatment, payment, enrollment, or eligibility for benefits is not conditioned on my providing such consent, and I may opt out at any time by written request to Natera. By providing my partner’s information, I am agreeing to share my test results with my partner and their healthcare provider for treatment purposes.
      </p>
    </article>
  );
};

export default NateraBodyContent;
