import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';
import { browserHistory } from 'react-router';


import SignupForm from '../../../../components/forms/TestRequest/SignupForm/SignupForm';
import schedulingService from '../../../../services/api/schedulingService';
import TestRequestService from '../../../../services/api/testRequestService';
import BreadcrumbProgress from 'components/forms/TestRequest/BreadcrumbProgress/BreadcrumbProgress';

import './TestRequestWizardPage.scss';
import LabInsuranceForm from 'components/forms/TestRequest/LabInsurnace/LabInsuranceForm';
import PayemtInfoForm from 'components/forms/TestRequest/Payemtninfo/PaymentInfoForm';
import { getCountryState } from '../../../../ducks/enums';
import ShippingAddressPage from '../../../../components/scp/forms/WizardForm/ShippingAddressPage/ShippingAddressPage';
import { getTestRequestData, setTestRequestData,clearTestRequestData } from '../../../../ducks/testRequestWizard';
import { loadLabTest } from '../../../../ducks/geneticTest';
import { closeLoader, showLoader } from '../../../../ducks/ui';
import webinarService from '../../../../services/api/webinarService';
import { setupStripeIntentFromEmail } from '../../../../ducks/user';
import ConfirmAppointmentForm from '../../../../components/forms/TestRequest/ConfirmAppointmentForm/ConfirmAppointmentForm';
import ConfirmationPage from '../../../../components/forms/TestRequest/ConfirmationPage/ConfirmationPage';
import authService from '../../../../services/api/authService';
import EmailAlreadyExist from '../../../../components/forms/TestRequest/EmailAlreadyExist/EmailAlreadyExist';
import usersService from '../../../../services/api/usersService';
import { getHomeAddress, getShippingAddress, HOME } from '../../../../services/utils';
import { dehydrateGeneTestOrder, dehydratePayment, dehydrateRegister } from '../../../../services/api/transformers/geneticTestTransformers';
import { processGeneTestOrderError } from '../../../../services/api/transformers/errorTransformers';
import { clearFormError, setSubmitFailure } from '../../../../ducks/registrationWizard';
import { formatOrderQues } from './AOEUtils';
import PrivacyPolicyForm from '../../../../components/forms/TestRequest/PrivacyPolicy/PrivacyPolicyForm';
import NateraConsents from '../../../../components/forms/TestRequest/NateraConsents/NateraConsents';

const SIGNUP_FORM = 'signup-form';
const GM_PRIVACY_POLICY = 'gm-privacy-policy';
const NATERA_CONSENTS = 'natera-consents';
const LAB_INSURANCE = 'lab-insurance';
const PAYMENT = 'payment';
const SHIPPING_ADDRESS = 'shipping address';
const REVIEW = 'review';
const CONFIRMATION = 'confirmation';
const EMAIL_EXIST = "email-already-exists";


export const workflow = [
  {
    id: SIGNUP_FORM,
    name: 'Signup',
    noProgress: true,
  },
  {
    id: GM_PRIVACY_POLICY,
    name: 'GM privacy policy',
    noProgress: true,
  },
  {
    id: NATERA_CONSENTS,
    name: 'Natera consent',
    noProgress: true,
  },
  {
    id: EMAIL_EXIST,
    name: 'Email already exist',
    noProgress: true,
  },
  {
    id: LAB_INSURANCE,
    name: 'Insurance',
  },
  {
    id: PAYMENT,
    name: 'Payment',
  },
  {
    id: SHIPPING_ADDRESS,
    name: 'Address',
  },
  {
    id: REVIEW,
    name: 'Review',
  },
  {
    id: CONFIRMATION,
    name: 'Confirmation',
    noProgress: true,
  },
];

export const SIGNUP_INITIALVALUES={
  firstName: '',
  lastName: '',
  email: '',
  primaryPhone: '',
  primaryPhoneConsentToText: '',
  dob: '',
  sexAssignedAtBirth: '',
  aoeForm:{}
}

class TestRequestWizardPage extends Component {
  state = {
    isMobile: false,
    activeStage: SIGNUP_FORM,
    formError:null,
    errors:null
  };

  componentDidMount() {
    const { dispatch,partnerData,testUUID } = this.props;
    if(!testUUID){
      browserHistory.push('/login');
    }
    this.setState({ isMobile: schedulingService.isMobile() });
    window.addEventListener('resize', this.onResize);
    if(partnerData && partnerData.uuid) {
    dispatch(getCountryState(partnerData.uuid));
    }
    const TestRequestData = getTestRequestData();
    const activeStage= _.get(TestRequestData,['activeStage'],SIGNUP_FORM);
    if(activeStage){
      this.updateStage(activeStage);
    }
    
  }

  componentWillUnmount(){
    console.log('unmount');
  }

  onResize = () => {
    this.setViewMode();
  };

  setViewMode() {
    const { isMobile } = this.state;
    if (isMobile && !schedulingService.isMobile()) {
      this.setState({ isMobile: false });
    } else if (!isMobile && schedulingService.isMobile()) {
      this.setState({ isMobile: true });
    }
  }

  clearSessionStorage = () =>{
    clearTestRequestData();
    authService.resetAffiliationId();
    authService.resetAffiliation();
    TestRequestService.clearGeneticTestUUID(); 
    TestRequestService.clearTestRequestLabUUID();   
  }

  removeErrorFromFields=(objName)=>{
    const {errors} =this.state;
    if(errors && errors[objName]){
      delete errors[objName];
      this.setState({
        errors
      })
    }
  }

  onSubmitSignupForm = (values) => {
    const { dispatch } = this.props;
    this.removeErrorFromFields('patient');
      dispatch(showLoader());
    return usersService.matchedPatient(values?.email).then(res => {
      if (!res.exist) {
        setTestRequestData({
          register: values,
          formatedAOE:formatOrderQues(values)
        });
        this.updateStage(GM_PRIVACY_POLICY);
      }
      else {
        this.updateStage(EMAIL_EXIST);
      }
      dispatch(closeLoader());
      return res;
    })
      .catch(() => {
        dispatch(closeLoader());
      })
  };

  submitPrivacyInformation = (values) => {
    return new Promise((resolve) => {
      resolve(values);
    }).then(() => {
      this.updateStage(NATERA_CONSENTS);
    });
  };
  
  submitNateraConsents = (values) => {
    return new Promise((resolve) => {
      resolve(values);
    }).then(() => {
      this.updateStage(LAB_INSURANCE);
    });
  };

  onInusranceFormSubmit = (values) => {
    return new Promise((resolve) => {
      resolve(values);
    }).then((values) => {
      setTestRequestData({
        lab_insurance: values
      });
      this.removeErrorFromFields('insurance');
      this.updateStage(PAYMENT);
      return values;
    });
  };

  onPaymentFormSubmit = (values) => {
    const {partnerData,user: { stripeIntentFromEmail }} =this.props;
    const { requireShippingAddress } = partnerData;
    const stripeSetupIntent = _.get(stripeIntentFromEmail,['id']);
    return new Promise((resolve) => {
      resolve(values);
    }).then((values) => {
      this.removeErrorFromFields('home');
      setTestRequestData({
        address: values,
        payment:{stripeSetupIntent}
      });
      if(requireShippingAddress)
        this.updateStage(SHIPPING_ADDRESS);
      else 
        this.updateStage(REVIEW);
    });
  };

  onAddressFormSubmit = (values) => {
    return new Promise((resolve) => {
      resolve(values);
    }).then((values) => {
      this.removeErrorFromFields('delivery');
      setTestRequestData({
        address: values
      })
      this.updateStage(REVIEW);
    });
  }

  onReviewSubmit = (values) => {
    const {partnerData,testUUID,labUUID,dispatch} = this.props;
    const testRequestData = getTestRequestData();
    const registerData = _.get(testRequestData,['register'],{});
    const labInsData=_.get(testRequestData,['lab_insurance'],{});
    const addressesData = _.get(testRequestData,['address'],[])
    const paymentData = _.get(testRequestData,['payment'],[]);
    const formatedAOE = _.get(testRequestData,['formatedAOE'],{})
    return new Promise((resolve) => {
      resolve(values);
    }).then((values) => {
      const req={
        patient:dehydrateRegister({
          ...registerData,
          userAddresses:addressesData,
          insurance:labInsData,
          partnerId:_.get(partnerData,['id'])
        }),
        payment: dehydratePayment({
          ...paymentData
        }),
        gene_test_order:dehydrateGeneTestOrder({
          labUUID:labUUID,
          testUUID:testUUID,
          fields:formatedAOE
        })
      }
      dispatch(showLoader());
      dispatch(clearFormError());
      usersService.createGeneTestOrder(req).then(res=>{
        this.updateStage(CONFIRMATION);
        dispatch(closeLoader());
        return res;
      })
      .catch(err=>{
        if(err && err.status == 422){
          const error= processGeneTestOrderError(err);
          this.setState({
            errors:error.fields
          });

          if(error.fields.patient) this.updateStage(SIGNUP_FORM);
          else if(error.fields.insurance) this.updateStage(LAB_INSURANCE);
          else if(error.fields.home) this.updateStage(PAYMENT);
          else if(error.fields.delivery) this.updateStage(SHIPPING_ADDRESS);
          dispatch(closeLoader());
        }
        else {
          dispatch(setSubmitFailure(err.message));
          dispatch(closeLoader());
        }
        
      })
    });

  }

  ShippingSameAsHome = (shippingAddress) => {
    if (!_.isEmpty(shippingAddress)) {
      const addressType = _.get(shippingAddress, 'addressType', []);
      if (addressType.includes(HOME)) return true;
    }
    return false;
  };

  updateStage = (activeStage) =>{
    setTestRequestData({
      activeStage
    });
    this.setState({activeStage});
  }

  onBack = () => {
    const {activeStage} =this.state;
    const {partnerData} =this.props;
    const { requireShippingAddress } = partnerData;
    switch (activeStage) {
      case GM_PRIVACY_POLICY:
        this.updateStage(SIGNUP_FORM); break;
      case NATERA_CONSENTS:
        this.updateStage(GM_PRIVACY_POLICY); break;
      case LAB_INSURANCE:
        this.updateStage(NATERA_CONSENTS); break;
      case PAYMENT:
        this.updateStage(LAB_INSURANCE); break;
      case SHIPPING_ADDRESS:
        this.updateStage(PAYMENT); break;
      case REVIEW: {
        if (requireShippingAddress)
          this.updateStage(SHIPPING_ADDRESS); 
        else
          this.updateStage(PAYMENT);
          break;
      }

    }

  }


  render() {
    const {getStripeIntent,user: { stripeIntentFromEmail, stripeIntentFromEmailLoading },partnerData,testDetail} =this.props;
    if(!partnerData) return null;
    const { activeStage, isMobile } = this.state;
    const {CountriesStateEnums} =this.props;
    const TestRequestData = getTestRequestData();
    const { preSelectedCountry, countryIsNonUS, allowedCountries, requireShippingAddress } = partnerData;
    const registerInitialValues = _.get(TestRequestData,['register'],{});
    const labInsurnaceInitialValues=_.get(TestRequestData,['lab_insurance'],{});
    const allAddress = _.get(TestRequestData,['address'],[])
    const homeAddress = getHomeAddress([...allAddress]);
    const shippingAddress = getShippingAddress([...allAddress]);
    
    const paymentInitialValues={
      ...homeAddress ,
      addressCountry: _.get(homeAddress, 'addressCountry', preSelectedCountry),
    }
    const TestRequestClass = [SIGNUP_FORM,GM_PRIVACY_POLICY, NATERA_CONSENTS].includes(activeStage);
    return (
      <>
        <div
          className={classnames('scp-schedule-container', isMobile && 'mobile-schedule-container')}
        >
          <div
            className={classnames('full-height', {
              'scheduling-page test-request': !TestRequestClass,
            })}
          >
            <BreadcrumbProgress activeStage={activeStage} workflow={workflow} />
            {activeStage == SIGNUP_FORM && (
              <SignupForm
                isMobile={this.state.isMobile}
                onSubmit={this.onSubmitSignupForm}
                initialValues={{
                  ...SIGNUP_INITIALVALUES,
                  ...registerInitialValues
                }
                }
                errors={this.state.errors}
                dispatch={this.props.dispatch}
              />
            )}
            {activeStage === GM_PRIVACY_POLICY && (
              <div className='test-request-privacy-policy'>
              <PrivacyPolicyForm
                onSubmit={this.submitPrivacyInformation}
                goBack={() => this.onBack()}
              />
              </div>
            )}
            {activeStage === NATERA_CONSENTS && (
              <NateraConsents
                onSubmit={this.submitNateraConsents}
                goBack={() => this.onBack()}
              />
            )}
            {activeStage == LAB_INSURANCE && (
              <LabInsuranceForm
                toPreviousStep={() => {
                  this.onBack();
                }}
                initialValues={{...labInsurnaceInitialValues}}
                onSubmit={this.onInusranceFormSubmit}
                errors={this.state.errors}
                dispatch={this.props.dispatch}
              />
            )}
            {activeStage == PAYMENT && (
              <PayemtInfoForm
                toPreviousStep={() => {
                  this.onBack();
                }}
                initialValues={{
                  ...paymentInitialValues
                }}
                CountriesStateEnums={CountriesStateEnums}
                onSubmit={this.onPaymentFormSubmit}
                getStripeIntent={getStripeIntent}
                email ={_.get(TestRequestData,['register','email'],'arpit.makhiyaviya+dev_12june@genomemedical.com')}
                stripeIntent={stripeIntentFromEmail}
                stripeIntentLoading={stripeIntentFromEmailLoading}
                allowedCountries={allowedCountries}
                countryIsNonUS={countryIsNonUS}
                allAddress={allAddress}
                user={{
                  fullName: `${_.get(TestRequestData, ['register', 'firstName'])} ${_.get(TestRequestData, ['register', 'lastName'])}`
                }}
                errors={this.state.errors}
                dispatch={this.props.dispatch}
                testDetail={testDetail}
              />
            )}
            {SHIPPING_ADDRESS == activeStage && requireShippingAddress && (
              <ShippingAddressPage
                toPreviousStep={() => {
                  this.onBack();
                }}
                initialValues={{
                  shippingAddressLine1: _.get(shippingAddress, 'addressLine1', ''),
                  shippingAddressLine2: _.get(shippingAddress, 'addressLine2', ''),
                  shippingAddressCity: _.get(shippingAddress, 'addressCity', ''),
                  shippingAddressState: _.get(shippingAddress, 'addressState', ''),
                  shippingAddressZipCode: _.get(shippingAddress, 'addressZipCode', ''),
                  shippingAddressCountry: _.get(shippingAddress, 'addressCountry', 'US'),
                  shippingSameAsHomeAddress: this.ShippingSameAsHome(shippingAddress),
                }}
                onSubmit={this.onAddressFormSubmit}
                allAddress={allAddress}
                user={{
                  fullName: `${_.get(TestRequestData, ['register', 'firstName'])} ${_.get(TestRequestData, ['register', 'lastName'])}`
                }}
                CountriesStateEnums={CountriesStateEnums}
                isMobile={this.state.isMobile}
                description={"We need to collect a shipping address so that Natera can send a genetic test kit to you."}
                errors={this.state.errors}
                dispatch={this.props.dispatch}
              />
            )}
            {activeStage == REVIEW &&(
              <ConfirmAppointmentForm 
              toPreviousStep={() => {
                this.onBack();
              }}
              onSubmit={this.onReviewSubmit}
              formError={this.props.formError}
              testDetail={testDetail}
              />
            )}
            {activeStage == CONFIRMATION && (
              <ConfirmationPage
                isMobile={this.state.isMobile}
              />
            )
            }
            {activeStage == EMAIL_EXIST &&(
              <EmailAlreadyExist />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default connect((state) => {
  return {
    CountriesStateEnums: _.get(state, ['enums', 'countryState', 'data']),
    geneticTest: _.get(state, ['geneticTest']),
    user:_.get(state,['user']),
    partnerData: authService.getAffiliation(),
    testUUID : TestRequestService.getGeneticTestUUID(),
    labUUID:TestRequestService.getTestRequestLabUUID(),
    testDetail:TestRequestService.getGeneticTestDetail(),
    formError : _.get(state,['registrationWizard','formError'])


  };
}, (dispatch) => {
  return {
    dispatch,
    loadLabTest: data => dispatch(loadLabTest(data)),
    showLoader: () => dispatch(showLoader()),
    closeLoader: () => dispatch(closeLoader()),
    partners: () => webinarService.getPartners(),
    getStripeIntent:(data)=>dispatch(setupStripeIntentFromEmail(data))

  };
})(TestRequestWizardPage);
